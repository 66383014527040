const host = process.env.VUE_APP_API_SERVER

const routes = {
  user: host + '/api/admin/user',
  company: host + '/api/admin/company',
  role: host + '/api/admin/role',
  zone: host + '/api/admin/zone',
  plant: host + '/api/admin/plant',
  unit: host + '/api/admin/unit',
  client: host + '/api/admin/client',
  project: host + '/api/admin/project',
  site: host + '/api/admin/site',
  product: host + '/api/admin/product',
  order: host + '/api/admin/order',
  inquiry: host + '/api/admin/inquiry',
}

export default  {
  routes 
}
