<template>
  <v-container fluid class="text-center">
    <v-card class="elevation-5 mt-5 mx-5">
      <v-toolbar dense dark color="secondary" elevation="2">
        <v-toolbar-title>
          Empresas
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn text small @click="dialogs.new = true">
          Agregar
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title>
        <v-btn small :loading="loading" elevation="0" color="secondary" outlined class="mx-2" @click="refresh()">
          Actualizar
          <v-icon right>mdi-refresh</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar" class="mx-2" single-line hide-details maxlength="150"></v-text-field>
      </v-card-title>
      <v-data-table v-model="selected" :headers="headers" :items="items" :loading="loading" :search="search"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'items-per-page-options': [20, 50, -1] }" :items-per-page="10" item-key="id" class="elevation-1" dense>
        <v-progress-linear slot="progress" color="secondary" indeterminate></v-progress-linear>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip left><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item)">mdi-pencil</v-icon></template><span>Editar</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'users')">mdi-account-multiple</v-icon></template><span>Usuarios asignados</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="view(item, 'zones')">mdi-map</v-icon></template><span>Asignar zonas</span></v-tooltip>
          <v-tooltip top><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="primary" dark v-bind="attrs" v-on="on" :disabled="loading" @click="getLogo(item.idempresa)">mdi-image</v-icon></template><span>Logo</span></v-tooltip>
          <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item.idempresa)">mdi-delete</v-icon></template><span>Eliminar</span></v-tooltip>
        </template>
        <template v-slot:[`footer.page-text`]="props">
          Página {{ props.pageStart }} - {{ props.pageStop }} de {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
          No hay información disponible
        </template>
        <template v-slot:no-results>
          No se obtuvieron resultados
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogs.new" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Nueva empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="save()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formNew" lazy-va2lidation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field v-model="forms.new.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.razon_social" label="Razón social" :rules="rules.razon_social" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.rfc" label="RFC" :rules="rules.rfc" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.new.direccion" label="Dirección" :rules="rules.direccion" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.new.email" label="Correo" :rules="rules.email" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.update" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Actualizar empresa</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-text-field v-model="forms.update.nombre" label="Nombre" :rules="rules.nombre" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.razon_social" label="Razón social" :rules="rules.razon_social" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.rfc" label="RFC" :rules="rules.rfc" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="forms.update.direccion" label="Dirección" :rules="rules.direccion" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.telefono" label="Teléfono" :rules="rules.telefono" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field v-model="forms.update.email" label="Correo" :rules="rules.email" :disabled="loading" dense outlined required></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.users" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Usuarios asignados</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUsers" lazy-validation>
              <v-row class="mt-2">
                <template v-for="(item, idx) in forms.update.usuarios">
                  <v-col cols="12" :key="idx">
                    <v-text-field :value="(item.detalles_usuarios[0].nombre + ' ' + item.detalles_usuarios[0].apellido_paterno + ' ' + item.detalles_usuarios[0].apellido_materno).trim() +' (' + item.email + ')'" readonly dense outlined required></v-text-field>
                  </v-col>
                </template>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.zones" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Asignar zonas</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="update()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdate" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-autocomplete v-model="forms.update.zonas" :items="catalogues.zonas" item-text="nombre" item-value="idzona" label="Zonas" dense outlined multiple persistent-hint></v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.logo" persistent max-width="600px">
      <v-card>
        <v-toolbar dense dark color="secondary" elevation="2">
          <v-toolbar-title>Logo</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" @click="cancel()">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small :loading="loading" @click="updateLogo()">
            Actualizar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formLogo" lazy-validation>
              <v-row class="mt-2">
                <v-col cols="12">
                  <v-file-input v-model="forms.logo.file" chips show-size  label="Seleccionar imagen..." accept="image/png" :disabled="loading" dense outlined></v-file-input>
                </v-col>
                <v-col v-if="forms.logo.image" cols="12">
                  <v-card color="">
                    <v-card-text>
                      <v-img :src="forms.logo.image" max-height="500" contain/>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogs.remove" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">Aviso</v-card-title>
        <v-card-text>¿Realmente desea eliminar la empresa?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text small :disabled="loading" color="blue-grey" @click="cancel()">
            Cancelar
          </v-btn>
          <v-btn text small :loading="loading" color="error" @click="remove()">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="success" color="success" :timeout="2500" bottom right>
      {{ msgSuccess }}
    </v-snackbar>
    <v-snackbar v-model="error" color="error" :timeout="2500" bottom right>
      {{ msgError }}
    </v-snackbar>
  </v-container>
</template>

<script>
import services from '@/utils/services'

export default {
  name: 'company',
  data: () => ({
    loading: false,
    success: false,
    msgSuccess: '',
    error: false,
    msgError: '',
    search: '',
    headers: [
      { text: 'Nombre', align: 'left', sortable: false, value: 'nombre' },
      { text: 'Razón social', align: 'left', sortable: false, value: 'razon_social' },
      { text: 'RFC', align: 'left', sortable: false, value: 'rfc' },
      { text: 'Correo', align: 'left', sortable: false, value: 'email' },
      { text: 'Acciones', align: 'left', sortable: false, value: 'actions' },
    ],
    items: [],
    selected: [],
    deleted: null,
    catalogues: {
      zonas: []
    },
    forms: {
      new: {
        nombre: '',
        razon_social: '',
        rfc: '',
        direccion: '',
        telefono: '',
        email: ''
      },
      update: {
        idempresa: '',
        nombre: '',
        razon_social: '',
        rfc: '',
        direccion: '',
        telefono: '',
        email: ''
      },
      logo: {
        empresa: null,
        file: null,
        image: null
      }
    },
    rules: {
      nombre: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 250) || 'El campo excede la longitud máxima'
      ],
      razon_social: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 250) || 'El campo excede la longitud máxima'
      ],
      rfc: [
        v => !!v || 'El campo es requerido',
        v => /^[ñA-Z]{3,4}[0-9]{6}[0-9A-Z]{3}$/.test(v) || 'El campo es inválido'
      ],
      direccion: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 250) || 'El campo excede la longitud máxima'
      ],
      telefono: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 15) || 'El campo excede la longitud máxima'
      ],
      email: [
        v => !!v || 'El campo es requerido',
        v => (v && v.length <= 320) || 'El campo excede la longitud máxima',
        v => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(v) || 'El campo es inválido'
      ]
    },
    dialogs: {
      new: false,
      update: false,
      users: false,
      zones: false,
      logo: false,
      remove: false
    }
  }),
  mounted() {
    this.refresh()
  },
  methods: {
    async refresh() {
      this.loading = true
      const zone = await this.axios.get(services.routes.zone)
      if (zone.data.data) {
        this.catalogues.zonas = zone.data.data
      }
      this.axios.get(services.routes.company)
        .then(response => {
          this.items = response.data.data
        })
        .catch(error => {})
        .finally(() => {
          this.loading = false
        })
    },
    cancel() {
      if (this.dialogs.new) {
        this.$refs.formNew.reset()
        for (let key in this.forms.new) {
          this.forms.new[key] = ''
        }
        this.dialogs.new = false
      }
      else if (this.dialogs.update || this.dialogs.zones) {
        this.$refs.formUpdate.reset()
        for (let key in this.forms.update) {
          this.forms.update[key] = ''
        }
        delete this.forms.update.zonas
        this.dialogs.update = false
        this.dialogs.zones = false
      }
      else if (this.dialogs.logo) {
        this.$refs.formLogo.reset()
        this.forms.logo = {
          empresa: null,
          file: null,
          image: null
        }
        this.dialogs.logo = false
      }
      else if (this.dialogs.remove) {
        this.dialogs.remove = false
      }
      this.dialogs.users = false
    },
    save() {
      if (this.$refs.formNew.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.new))
        this.axios.post(services.routes.company, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Empresa creada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    view(data, type) {
      this.forms.update = {
        idempresa: data.idempresa,
        nombre: data.nombre,
        razon_social: data.razon_social,
        rfc: data.rfc,
        direccion: data.direccion,
        telefono: data.telefono,
        email: data.email
      }
      if (type === 'users') {
        this.forms.update.usuarios = []
        for (let i = 0; i < data.usuarios_empresas.length; i++) {
          this.forms.update.usuarios.push(data.usuarios_empresas[i].usuario)
        }
        this.dialogs.users = true
      }
      else if (type === 'zones') {
        this.forms.update.zonas = []
        for (let i = 0; i < data.empresas_zonas.length; i++) {
          this.forms.update.zonas.push(data.empresas_zonas[i].zona.idzona)
        }
        this.dialogs.zones = true
      }
      else {
        this.dialogs.update = true
      }
    },
    getLogo(id) {
      this.loading = true
      this.axios.get(services.routes.company + '/logo?id=' + id)
        .then(response => {
          let data = response.data.data
          this.forms.logo.empresa = id
          if (data) {
            data = data.data
            this.forms.logo.image = 'data:image/png;base64,' + btoa(new Uint8Array(data).reduce((data, byte) => data + String.fromCharCode(byte), ''))
          }
          this.dialogs.logo = true
        })
        .catch(error => {
          this.error = true
          this.msgError = error.response.data.message || 'Servicio no disponible'
        })
        .finally(() => {
          this.loading = false
        })
    },
    updateLogo() {
      if (this.forms.logo.file) {
        let fr = new FileReader();
        let file = this.forms.logo.file
        let size = file.size
        if (size <= 1000000) {
          fr.onload = () => {
            this.loading = true
            let data = {
              idempresa: this.forms.logo.empresa,
              logo: fr.result.split(',')[1]
            }
            this.axios.put(services.routes.company + '/logo', { data })
              .then(response => {
                this.cancel()
                this.refresh()
                this.success = true
                this.msgSuccess = 'Logo actualizado exitosamente'
              })
              .catch(error => {
                this.error = true
                this.msgError = error.response.data.message || 'Servicio no disponible'
              })
              .finally(() => {
                this.loading = false
              })
          }
          fr.onerror = () => {
            this.loading = false
            this.forms.logo.file = null
            this.error = true
            this.msgError = 'El archivo no fue leído correctamente'
          }
          fr.readAsDataURL(file);
        }
        else {
          this.error = true
          this.msgError = 'El archivo ha excedido el límite permitido (1 MB)'
        }
      }
      else {
        this.error = true
        this.msgError = 'Seleccionar un archivo'
      }
    },
    update() {
      if (this.$refs.formUpdate.validate()) {
        this.loading = true
        let data = JSON.parse(JSON.stringify(this.forms.update))
        this.axios.put(services.routes.company, { data })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Empresa actualizada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.error = true
        this.msgError = 'La información es incorrecta'
      }
    },
    remove(id) {
      if (!id) {
        this.loading = true
        this.axios.delete(services.routes.company, { data: { data: { idempresa: this.deleted } } })
          .then(response => {
            this.cancel()
            this.refresh()
            this.success = true
            this.msgSuccess = 'Empresa eliminada exitosamente'
          })
          .catch(error => {
            this.error = true
            this.msgError = error.response.data.message || 'Servicio no disponible'
          })
          .finally(() => {
            this.loading = false
          })
      }
      else {
        this.deleted = id
        this.dialogs.remove = true
      }
    }
  }
}
</script>

<style scoped>
</style>
